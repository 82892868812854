import React from "react"
import { graphql, useStaticQuery } from 'gatsby'
import { Text, Box } from "@chakra-ui/core";
import BackgroundSlider from 'gatsby-image-background-slider'
import ContactForm from './contact'
import Typist from 'react-typist'
import LogoImg from "../images/logo.png"
import "../components/layout.css"

const IndexPage = () => (
  <>
      <Box width={{ base: 100, md: 150 }} ml={3} mt={3}><img width="100%" src={LogoImg} alt="First Swiss group"/></Box>
      <main>
        <Box visibility={{ base: 'hidden', md: 'visible'}}>
          <Text textAlign="center" color="white" mb={{ base: 0, md: 2 }} mt={{ base: 8, lg: 16}} fontSize={{ base: '4xl', lg: '6xl'}} fontWeight={600}>FIRST SWISS GROUP</Text>
          <Typist cursor={{ show: false }}>
            <Text textAlign="center" color="white" fontSize={{ base: 'sm', lg: 'xl'}} fontWeight={500}>Smart investing since 2009</Text>
          </Typist>
        </Box>
        <ContactForm/>
      </main>
    <BackgroundSlider
      query={useStaticQuery(graphql`
          query {
            backgrounds: allFile (filter: {sourceInstanceName: {eq: "backgrounds"}}){
              nodes {
                relativePath
                childImageSharp {
                  fluid (maxWidth: 4000, quality: 90){
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        `)}
      initDelay={6} // delay before the first transition (if left at 0, the first image will be skipped initially)
      transition={2} // transition duration between images
      duration={6} // how long an image is shown
      // specify images to include (and their order) according to `relativePath`
      images={["switzerland-2.jpg", "switzerland-3.jpg", "switzerland-1.jpg", "switzerland-4.jpg", "switzerland-5.jpeg"]}

    />

  </>

)

export default IndexPage
